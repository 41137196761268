/**
 * 获取素材路径
 */
export default {
	fullImgPath(imgurl) {
		if (imgurl != '' && imgurl != null) {
			if (imgurl.substr(0, 4).toLowerCase() == "http") {
				return imgurl
			} else {
				return 'https://ytwl-app.oss-cn-chengdu.aliyuncs.com/image/' + imgurl
			}
		} else {
			return ''//'https://ytwl-app.oss-cn-chengdu.aliyuncs.com/icon/logo.png'
		}
	}
}