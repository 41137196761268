import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		scrollIndex: 0,
		isLogin: false,
		phone: '',
		loginInfo: {}
	},
	getters: {},
	mutations: {
		savaScrollIndex(state, index) {
			state.scrollIndex = index;
		},
		saveLoginInfo(state, info) {
			state.loginInfo = info;
			state.phone = info.loginAccount;
			state.isLogin = true;
			localStorage.setItem('loginInfo',JSON.stringify(info))
			localStorage.setItem('accessToken',info.accessToken);
		},
		clearLoginInfo(state) {
			state.loginInfo = {};
			state.phone = '';
			state.isLogin = false;
			localStorage.setItem('loginInfo','')
			localStorage.setItem('accessToken','');
		}
	},
	actions: {},
	modules: {}
})