import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routes = [{
	path: '/index',
	name: 'index',
	component: () => import('../views/index/index.vue'),
	children: [{
		path: '/home',
		name: 'home',
		component: () => import('../views/home/index.vue'),
	}, {
		path: '/goods',
		name: 'goods',
		component: () => import('../views/goods/index.vue')
	}, {
		path: '/article',
		name: 'article',
		component: () => import('../views/article/index.vue')
	}]
}, {
	path: '/',
	redirect: '/home'
}]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router